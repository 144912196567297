import React from 'react';
import styled from "styled-components";

const RewardsInfoContainer = styled.div`
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: grid;
    gap: 1.5rem;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const RewardsSection = styled.div`
    text-align: center;
    h3 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
        color: #fff;
    }
`;

const RarityList = styled.div`
    display: grid;
    gap: 0.75rem;
    text-align: left;
`;

const RarityItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;

    span:last-child {
        font-weight: bold;
        color: #fff;
    }
`;

const DailyEarnings = styled.div`
    text-align: center;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;


    h4 {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
        color: #fff;
    }

    p {
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        margin: 0;
    }
`;

const calculateTokensPerDay = (rarity) => {
    switch (rarity.toLowerCase()) {
        case 'common':
            return 2000;
        case 'legendary':
            return 20000;
        case 'epic':
            return 10000;
        case 'rare':
            return 4000;
        default:
            return 0;
    }
};

const calculateTotalDailyRewards = (stakedTokens) => {
    return stakedTokens.reduce((total, token) => {
        return total + calculateTokensPerDay(token.rarity);
    }, 0);
};

const RewardsInfo = ({stakedTokens, accumulatedRewards}) => {
    const totalDaily = calculateTotalDailyRewards(stakedTokens);

    return (
        <RewardsInfoContainer>
            <RewardsSection>
                <h3>Daily Rewards by Rarity</h3>
                <RarityList>
                    <RarityItem>
                        <span>Common</span>
                        <span>2,000 $LOOT</span>
                    </RarityItem>
                    <RarityItem>
                        <span>Rare</span>
                        <span>4,000 $LOOT</span>
                    </RarityItem>
                    <RarityItem>
                        <span>Epic</span>
                        <span>10,000 $LOOT</span>
                    </RarityItem>
                    <RarityItem>
                        <span>Legendary</span>
                        <span>20,000 $LOOT</span>
                    </RarityItem>
                </RarityList>
            </RewardsSection>

            <RewardsSection>
                <h3>Your Earnings</h3>
                <RarityList>
                    <DailyEarnings>
                        <h4>Unclaimed $LOOT</h4>
                        <p>{accumulatedRewards}</p>
                    </DailyEarnings>
                    <DailyEarnings>
                        <h4>Total Daily Rewards</h4>
                        <p>{totalDaily.toLocaleString()} $LOOT</p>
                    </DailyEarnings>
                </RarityList>
            </RewardsSection>
        </RewardsInfoContainer>
    );
};

export default RewardsInfo;