import React from "react";
import styled from "styled-components";

import MintButton from "./MintButton";

const Section = styled.section`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: #473266;
`;

const MintSite = () => {
  return (
    <Section>
      <MintButton></MintButton>
    </Section>
  );
};

export default MintSite;
