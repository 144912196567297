import styled from "styled-components";
import {Container} from "react-bootstrap";

export const StakingContainer = styled(Container)`
  padding: 2rem 1rem;
  background: linear-gradient(135deg, #2a1b3d 0%, #1b1b2f 100%);
  color: #fff;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  
  @media (max-width: 600px) {
    gap: 0.5rem;
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #fff;
      
  }
`;

export const UnclaimedRewards = styled.div`
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 1rem;
    text-align: center;
    margin-bottom: 2rem;

    h3 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: bold;
    }

    p {
        margin: 0.5rem 0 0;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
    }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const GridSection = styled.div`
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    padding: 1.5rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
        color: #fff;
    }
`;

export const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 1rem;
    padding: 1rem;
`;

export const ImageWrapper = styled.div`
    position: relative;
    aspect-ratio: 1;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-4px);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: ${props => props.selected ? 'grayscale(100%) brightness(40%)' : 'none'};
    }

    .rarity-badge {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.3rem;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        text-align: center;
        font-size: 0.8rem;
        text-transform: uppercase;
    }

    .selected-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: bold;
        opacity: ${props => props.selected ? 1 : 0};
        transition: opacity 0.3s ease;
    }
`;

export const SelectAllButton = styled.button`
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.9rem;
    transition: color 0.3s ease;

    &:hover {
        color: white;
    }
`;