import React from "react";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useWeb3Modal } from "@web3modal/ethers/react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  Container,
  LogoText,
  Box1,
  Connected1,
  ImageBox,
  SubText3, SubTextLight,
} from "../styling/styling";
import images from "../styling/images";
import {getLootBoxContract} from "../contracts/lootBox";
import WaitForTransactionModal from "../common/waitForTransaction";
import {getMemeNftContract} from "../contracts/memeNft";

const OpenChest = () => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [mintStatus, setMintStatus] = useState();
  const [nrOfOwnedBoxes, setNrOfOwnedBoxes] = useState(1);
  const [show, setShow] = useState(false);
  const [mintedNftImage, setMintedNftImage] = useState(images.nft1);
  const [successMessage, setSuccessMessage] = useState("");

  const exitMint = async () => {
    handleClose(true);
    setMintStatus(null);
    await fetchNrOwnableBoxes();
  };
  const handleClose = () => setShow(false);

  async function handleOpenBox() {
    setShow(true);

    if (!isConnected) throw Error("User disconnected");
    const memeNftContract = await getMemeNftContract(ethers, walletProvider);
    memeNftContract.on("NFTMinted", (to, tokenIds, imageNumber) => {
      //console.log("rarity minted: " + rarityTexts);
      if (to === address) {

        const mintedImage = images.nftImages[imageNumber];
        setMintedNftImage(mintedImage);
        setSuccessMessage("Congrats! You got a " /* + rarityTexts[0]*/)
        setMintStatus(1);
        memeNftContract.removeAllListeners("NFTMinted");
      }
    });
    const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
    try {
      await lootBoxContract.openBox();
    } catch (err) {
      console.log("error: ", err);
    }

  }

  const fetchNrOwnableBoxes = async () => {
    if (!address) return;

    const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
    const balance = await lootBoxContract.balanceOf(address, 1);
    setNrOfOwnedBoxes(balance);
  };

  useEffect(() => {
    if (isConnected && address) {
      const fetchOwnableBoxes = async () => {
        await fetchNrOwnableBoxes();
      };

      // Fetch initially
      fetchOwnableBoxes().catch((error) => {
        console.error("Error in fetchOwnableBoxes:", error);
      });

      const intervalId = setInterval(fetchOwnableBoxes, 30000); // Poll every 30 seconds
      return () => clearInterval(intervalId);
    }
  }, [isConnected, address]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [address]);

  return (
    <Container>
      <Box1>
        <ImageBox>
          {" "}
          <img src={images.chest} width="400px" alt=""></img>
        </ImageBox>
        <SubTextLight>Open a Loot Box (you own {nrOfOwnedBoxes.toString()})</SubTextLight>
        <Connected1>
          <div className={`sectimint ${!isConnected ? "" : "hiddenav"}`}>
            <LogoText onClick={() => open()}>
              <img src={images.connect} width="250px" alt=""></img>
            </LogoText>
          </div>
          <div className={`sectimint ${!isConnected ? "hiddenav" : ""}`}>
            <SubText3></SubText3>
            <LogoText
                onClick={handleOpenBox}
                disabled={nrOfOwnedBoxes < 1}
            >
              <img src={images.openButton} width="150px" alt=""></img>
            </LogoText>
          </div>
        </Connected1>
      </Box1>

      <WaitForTransactionModal
          show={show}
          handleClose={handleClose}
          transactionStatus={mintStatus}
          waitForTransImage={images.rollingart}
          transFinishedImage={mintedNftImage}
          waitForTransactionMessage={"Confirm the transaction to\n crack open your Loot Box.\nTreasures await!"}
          successMessage={"Congratulations!\n You've scored a stakable NFT masterpiece\n and bagged yourself 60 000 $LOOT! "}
          exitMint={exitMint}
      />
    </Container>
  );
};

export default OpenChest;
