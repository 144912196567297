const contractABI = require('./ogKeyAbi.json')

export const getOgKeyContract = async (ethers, walletProvider) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new ethers.Contract(process.env.REACT_APP_OGKEY_CONTRACT_ADDRESS, contractABI, signer);
        return contract;
    } catch (error) {
        console.error("Failed to get contract:", error);
        throw error;
    }
};