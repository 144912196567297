import React, {useEffect, useState} from "react";
import {ethers} from "ethers";
import {useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider} from "@web3modal/ethers/react";
import {LogoText} from "../styling/styling";
import {StakingContainer, ActionButtons } from "./components";
import images from "../styling/images";
import {getStakeContract} from "../contracts/stake";
import {getMemeNftContract} from "../contracts/memeNft";
import GridComponent from "./Grid";
import WaitForTransactionModal from "../common/waitForTransaction";
import ApprovalModal from "./ApprovalForAllModal";
import RewardsInfo from './RewardsInfo.js';

const Stake = () => {
    const {open} = useWeb3Modal();
    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();
    const [selectedOwnable, setSelectedOwnable] = useState([]);
    const [selectedStaked, setSelectedStaked] = useState([]);
    const [stakeStatus, setStakeStatus] = useState();
    const [stakeMessage, setStakeMessage] = useState("");
    const [stakeSuccessMessage, setStakeSuccessMessage] = useState("");
    const [isApprovedForAll, setIsApprovedForAll] = useState(false);
    const [ownedTokens, setOwnedTokens] = useState([]);
    const [stakedTokens, setStakedTokens] = useState([]);
    const [accumulatedRewards, setAccumulatedRewards] = useState("0");
    const [show, setShow] = useState(false);

    const exitMint = async () => {
        handleClose(true);
        setStakeStatus(null);
    };
    const handleClose = () => setShow(false);

    async function handleApproveAll() {
        setStakeMessage("Approve all to allow staking of your NFTs");
        setStakeSuccessMessage("Success!\n Time to stake those memes\n and let the earning begin!")
        setShow(true);
        if (!isConnected) throw Error("User disconnected");
        const memeContract = await getMemeNftContract(ethers, walletProvider);
        try {
            const response = await memeContract.setApprovalForAll(process.env.REACT_APP_STAKE_CONTRACT_ADDRESS, true);
            console.log("Set approval for all: ", response);
            const receipt = await response.wait();
            if(receipt.status === 1) {
                setIsApprovedForAll(true);
                setStakeStatus(1);
            }
          //  setIsApprovedForAll(response);
        } catch (err) {
            console.log("error: ", err);
        }
    }

    async function getRarity(tokenId) {
        if (!isConnected) throw Error("User disconnected");
        const memeContract = await getMemeNftContract(ethers, walletProvider);
        try {
            return await memeContract.getTokenRarity(tokenId);
        } catch (err) {
            console.log("error: ", err);
        }
    }

    async function checkIsApprovedForAll() {
        if (!isConnected) throw Error("User disconnected");
        const memeContract = await getMemeNftContract(ethers, walletProvider);
        try {
            const response = await memeContract.isApprovedForAll(address, process.env.REACT_APP_STAKE_CONTRACT_ADDRESS);
            //console.log("Is approval for all: ", response);
            setIsApprovedForAll(response);
          //  setStakeStatus(1);
        } catch (err) {
            console.log("error: ", err);
        }
    }

    useEffect(() => {
        const fetchNFTsAsync = async () => {
            await fetchOwnedNFTs();
            await fetchStakedNFTs();
            await checkIsApprovedForAll();
            await fetchAccumulatedRewards();
        };

        if (isConnected && address) {
            fetchNFTsAsync().catch((error) => {
                console.error("Error in fetchNFTsAsync:", error);
            });

            const intervalId = setInterval(() => {
                fetchNFTsAsync().catch((error) => {
                    console.error("Error in fetchNFTsAsync:", error);
                });
            }, 30000); // Poll every 30 seconds

            // Clear interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [isConnected, address]);

    const fetchStakedNFTs = async () => {
        if (!address) return;

        const stakeContract = await getStakeContract(ethers, walletProvider);
        const stakedTokens = await stakeContract.getStakedTokens(address);
        let tokenMap = stakedTokens.map(token => token.toString())
        const memeContract = await getMemeNftContract(ethers, walletProvider);

        const tokens = [];
        for (let i = 0; i < tokenMap.length; i++) {
            const tokenUri = await memeContract.tokenURI(tokenMap[i].toString());
            const tokenMetadata = JSON.parse(atob(tokenUri.split(',')[1]));
            const imageNumber = tokenMetadata.image.split('/').pop().split('.')[0];
            const image = images.nftImages[imageNumber];
            const rarity = tokenMetadata.attributes[0].value;
            tokens.push({id: tokenMap[i].toString(), rarity, image});
        }
        setStakedTokens(tokens.sort((a, b) => a.id - b.id));
    };

    const fetchAccumulatedRewards = async () => {
        if (!address) return;

        try {
            const stakeContract = await getStakeContract(ethers, walletProvider);
            const rewards = await stakeContract.showAccumulatedRewards(address);
            const rewardsFormatted = ethers.formatUnits(rewards, 18);
            const rewardsFormattedToTwoDecimals = parseFloat(rewardsFormatted).toFixed(2);
            setAccumulatedRewards(rewardsFormattedToTwoDecimals.toString());
        } catch (error) {
            console.error("Error fetching accumulated rewards:", error);
        }
    };

    const fetchOwnedNFTs = async () => {
        if (!address) return;

        const memeContract = await getMemeNftContract(ethers, walletProvider);
        const balance = await memeContract.balanceOf(address);

        const tokens = [];
        for (let i = 0; i < balance; i++) {
            const tokenId = await memeContract.tokenOfOwnerByIndex(address, i);
            const tokenUri = await memeContract.tokenURI(tokenId);
            const tokenMetadata = JSON.parse(atob(tokenUri.split(',')[1]));
            const imageNumber = tokenMetadata.image.split('/').pop().split('.')[0];
            const image = images.nftImages[imageNumber];
            const rarity = tokenMetadata.attributes[0].value;
            tokens.push({id: tokenId.toString(), rarity, image});
        }
        // console.log("Tokens: ", tokens);

        setOwnedTokens(tokens);
    };

    async function handleStake() {
        if (selectedOwnable.length === 0) return;
        setStakeMessage("Punch that confirm button\n and let your MEMEs stake hard\n to stack up that $LOOT!")
        setStakeSuccessMessage("Boom!\n Your MEMEs are officially staked and\n grinding to earn you that sweet $LOOT!")
      
        setShow(true);

        if (!isConnected) throw Error("User disconnected");
        const stakeContract = await getStakeContract(ethers, walletProvider);

        stakeContract.on("Staked", (user, tokenId, rarity, timestamp) => {

            if (user === address) {
                setStakeStatus(1);
                setSelectedOwnable([]);
                fetchOwnedNFTs();
                fetchStakedNFTs();
                stakeContract.removeAllListeners("Staked");
            }
        });

        try {
            console.log("Selected tokens:" + selectedOwnable)
            const response = await stakeContract.stake(selectedOwnable);

            console.log("stake response:", response);
        } catch (err) {
            console.log("error: ", err);
        }
    }

    async function handleUnstake() {
        if (selectedStaked.length === 0) return;
        setStakeMessage("Hit confirm and hang tight\nyour meme masterpiece is about\n to return to your wallet.")
        setStakeSuccessMessage("Boom! \nYour meme art is back in your wallet baby.")
        setShow(true);

        if (!isConnected) throw Error("User disconnected");
        const stakeContract = await getStakeContract(ethers, walletProvider);

        stakeContract.on("Unstaked", (user, tokenId, rarity, timestamp) => {
            //const senda = to.toLowerCase();
            console.log("USER: " + user);
            console.log(tokenId);
            console.log(rarity);
            console.log(timestamp);
            if (user === address) {
                setStakeStatus(1);
                setSelectedStaked([]);
                fetchOwnedNFTs();
                fetchStakedNFTs();
                stakeContract.removeAllListeners("Unstaked");
            }
        });

        try {
            console.log("Selected tokens:" + selectedStaked)
            const response = await stakeContract.unstake(selectedStaked);

            console.log("unstake response:", response);
        } catch (err) {
            console.log("error: ", err);
        }
    }

    async function handleWithdrawAllRewards() {
        if (parseFloat(accumulatedRewards) === 0) return;

        setStakeMessage("Hit confirm and hold on tight.\nYour $LOOT is on its way to your stash!")
        setStakeSuccessMessage("Success!\n Your $LOOT has been claimed and \n added to your treasure pile.\n Time to flex!")
        setShow(true);
        if (!isConnected) throw Error("User disconnected");
        const stakeContract = await getStakeContract(ethers, walletProvider);

        try {
            const response = await stakeContract.withdrawAllRewards();
            const receipt = await response.wait();
            if(receipt.status === 1) {
                setStakeStatus(1);
            }
        } catch (err) {
            console.log("error: ", err);
        }
    }


    useEffect(() => {
        // eslint-disable-next-line
    }, [address]);

    return (

        <div>
            <div className={`sectimint ${!isConnected ? "" : "hiddenav"}`}>
                <LogoText onClick={() => open()}>
                    <img src={images.connect} width="250px" alt=""></img>
                </LogoText>
            </div>

            <ApprovalModal
                isOpen={isConnected && !isApprovedForAll}
                onApprove={handleApproveAll}
            />

            <div className={`sectimint`}>

                <StakingContainer fluid>
                    <RewardsInfo stakedTokens={stakedTokens} accumulatedRewards={accumulatedRewards}/>


                    <GridComponent
                        ownableTokens={ownedTokens}
                        stakedTokens={stakedTokens}
                        setSelectedOwnable={setSelectedOwnable}
                        setSelectedStaked={setSelectedStaked}
                        selectedOwnable={selectedOwnable}
                        selectedStaked={selectedStaked}
                    />
                    <div className={`sectimint ${isConnected && isApprovedForAll ? "" : "hiddenav"}`}>
                        <ActionButtons>
                            <LogoText
                                onClick={handleStake}
                                disabled={selectedOwnable.length === 0}
                            >
                                <img src={images.stake} width="150px" alt=""></img>
                            </LogoText>
                            <LogoText
                                onClick={handleUnstake}
                                disabled={selectedStaked.length === 0}
                            >
                                <img src={images.unstake} width="150px" alt=""></img>
                            </LogoText>
                            <LogoText
                                onClick={handleWithdrawAllRewards}
                                disabled={parseFloat(accumulatedRewards) === 0}
                            >
                                <img src={images.withdraw} width="150px" alt=""></img>
                            </LogoText>
                        </ActionButtons>
                    </div>
                </StakingContainer>
            </div>

            <WaitForTransactionModal
                show={show}
                handleClose={handleClose}
                transactionStatus={stakeStatus}
                waitForTransImage={images.coin}
                transFinishedImage={images.coin}
                waitForTransactionMessage={stakeMessage}
                successMessage={stakeSuccessMessage}
                exitMint={exitMint}
            />
        </div>
    );
};

export default Stake;
