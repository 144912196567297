import React from 'react';
import {GridContainer, GridSection, ImageGrid, ImageWrapper, SelectAllButton, StakingContainer} from "./components";

const GridComponent = ({
                           ownableTokens = [],
                           stakedTokens = [],
                           selectedOwnable,
                           selectedStaked,
                           setSelectedOwnable,
                           setSelectedStaked
                       }) => {
    const handleSelect = (token, gridType) => {
        if (gridType === 'ownable') {
            setSelectedOwnable(prev =>
                prev.includes(token) ? prev.filter(t => t !== token) : [...prev, token]
            );
        } else {
            setSelectedStaked(prev =>
                prev.includes(token) ? prev.filter(t => t !== token) : [...prev, token]
            );
        }
    };

    const handleSelectAll = (gridType) => {
        if (gridType === 'ownable') {
            setSelectedOwnable(
                selectedOwnable.length === ownableTokens.length
                    ? []
                    : ownableTokens.map(token => token.id)
            );
        } else {
            setSelectedStaked(
                selectedStaked.length === stakedTokens.length
                    ? []
                    : stakedTokens.map(token => token.id)
            );
        }
    };

    return (
        <StakingContainer fluid>

            <GridContainer>
                <GridSection>
                    <h2>In Wallet</h2>
                    <SelectAllButton onClick={() => handleSelectAll('ownable')}>
                        {selectedOwnable.length === ownableTokens.length ? 'Deselect All' : 'Select All'}
                    </SelectAllButton>
                    <ImageGrid>
                        {ownableTokens.map((token, index) => (
                            <ImageWrapper
                                key={index}
                                selected={selectedOwnable.includes(token.id)}
                                onClick={() => handleSelect(token.id, 'ownable')}
                            >
                                <img src={token.image} alt={`NFT ${token.id}`} />
                                <div className="rarity-badge">{token.rarity}</div>
                                {selectedOwnable.includes(token.id) && (
                                    <div className="selected-overlay">SELECTED</div>
                                )}
                            </ImageWrapper>
                        ))}
                    </ImageGrid>
                </GridSection>

                <GridSection>
                    <h2>Staked</h2>
                    <SelectAllButton onClick={() => handleSelectAll('staked')}>
                        {selectedStaked.length === stakedTokens.length ? 'Deselect All' : 'Select All'}
                    </SelectAllButton>
                    <ImageGrid>
                        {stakedTokens.map((token, index) => (
                            <ImageWrapper
                                key={index}
                                selected={selectedStaked.includes(token.id)}
                                onClick={() => handleSelect(token.id, 'staked')}
                            >
                                <img src={token.image} alt={`NFT ${token.id}`} />
                                <div className="rarity-badge">{token.rarity}</div>
                                {selectedStaked.includes(token.id) && (
                                    <div className="selected-overlay">SELECTED</div>
                                )}
                            </ImageWrapper>
                        ))}
                    </ImageGrid>
                </GridSection>
            </GridContainer>
        </StakingContainer>
    );
};

export default GridComponent;

