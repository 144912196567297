const contractABI = require('./lootBoxAbi.json')

export const getLootBoxContract = async (ethers, walletProvider) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new ethers.Contract(process.env.REACT_APP_LOOTBOX_CONTRACT_ADDRESS, contractABI, signer);
        return contract;
    } catch (error) {
        console.error("Failed to get contract:", error);
        throw error;
    }
};

export const getLootBoxReadOnlyContract = async (ethers) => {
    try {
        const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_INFURA_RPC_PROVIDER);
        return new ethers.Contract(process.env.REACT_APP_LOOTBOX_CONTRACT_ADDRESS, contractABI, provider);
        
    } catch (error) {
        console.error("Failed to get contract:", error);
        throw error;
    }
};




