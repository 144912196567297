const contractABI = require('./memeAbi.json')

export const getMemeNftContract = async (ethers, walletProvider) => {
    try {
        const ethersProvider = new ethers.BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const contract = new ethers.Contract(process.env.REACT_APP_MEME_NFT_CONTRACT_ADDRESS, contractABI, signer);
        return contract;
    } catch (error) {
        console.error("Failed to get contract:", error);
        throw error;
    }
};