import Modal from "react-bootstrap/Modal";

import images from "../styling/images";
import React from "react";

const WaitForTransactionModal = ({ show, handleClose, transactionStatus, waitForTransImage, transFinishedImage, waitForTransactionMessage, successMessage, exitMint }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            bsPrefix="CustomModal"
        >
            <Modal.Body>
                <div className={`poff ${transactionStatus ? "hiddenav" : ""}`}>
                    <div className="poffimg">
                        <img className="round-corners" src={waitForTransImage} alt="Loading..."></img>
                    </div>
                    <div className="pofftext">
                        <p>{waitForTransactionMessage}</p>
                    </div>
                    <div className="poffbutt">

                        <img src={images.close} onClick={exitMint} alt="Close"></img>

                    </div>
                </div>
                <div className={`poff ${transactionStatus ? "" : "hiddenav"}`}>
                    <div className="poffimg">
                        <img className="round-corners" src={transFinishedImage} alt="Unrevealed"></img>
                    </div>
                    <div className="pofftext">
                        <p>{successMessage}</p>
                    </div>
                    <div className="poffbutt">
                  
                        <img src={images.close} onClick={exitMint} alt="Close"></img>
                  
                </div>
                </div>
                
            </Modal.Body>
        </Modal>
    );
};

export default WaitForTransactionModal;
