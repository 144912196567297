// src/assets/images.js
import mintButton from "../assets/mintbut.png";
import rolling from "../assets/rolling.gif";
import unreveal from "../assets/unrev.gif";
import rollingart from "../assets/rolling2.gif";
import connect from "../assets/connbut.png";
import close from "../assets/closebut.png";
import chest from "../assets/kista.webp";
import minus from "../assets/minus.png";
import plus from "../assets/plus.png";
import stake from "../assets/Stakebut.png";
import unstake from "../assets/unstake.png";
import stakeb from "../assets/stake.png";
import mintb from "../assets/mintb.png";
import openb from "../assets/open.png";
import presale from "../assets/presalemint.png";
import what from "../assets/whatyouget.png";
import what2 from "../assets/what2.png";
import inbox from "../assets/inbox.png";
import openButton from "../assets/openbox.png";
import withdraw from "../assets/witdraw.png";
import common from "../assets/common.png";
import rare from "../assets/rare.png";
import epic from "../assets/epic.png";
import textopenpoff from "../assets/textopenpoff.png";
import textstakepoff from "../assets/textpoffstake.png";
import textunstakepoff from "../assets/textpoffunstake.png";
import textclaimpoff from "../assets/textpoffclaimloot.png";
import textapppoff from "../assets/textpoffapprove.png";
import floating from "../assets/float2.gif";
import textmint from "../assets/textmint.png";
import legendary from "../assets/legendary.png";
import approve from "../assets/approveall.png";
import nft1 from "../assets/testMemeNfts/1.png";
import nft2 from "../assets/testMemeNfts/2.png";
import nft3 from "../assets/testMemeNfts/3.png";
import nft4 from "../assets/testMemeNfts/4.png";
import nft5 from "../assets/testMemeNfts/5.png";
import nft6 from "../assets/testMemeNfts/6.png";
import nft7 from "../assets/testMemeNfts/7.png";
import nft8 from "../assets/testMemeNfts/8.png";
import nft9 from "../assets/testMemeNfts/9.png";
import nft10 from "../assets/testMemeNfts/10.png";
import nft11 from "../assets/testMemeNfts/11.png";
import nft12 from "../assets/testMemeNfts/12.png";
import nft13 from "../assets/testMemeNfts/13.png";
import nft14 from "../assets/testMemeNfts/14.png";
import nft15 from "../assets/testMemeNfts/15.png";
import nft16 from "../assets/testMemeNfts/16.png";
import nft17 from "../assets/testMemeNfts/17.png";
import textaftermint from "../assets/textaftermint.png";
import textafteropen from "../assets/textafteropen.png";
import successfullystaked from "../assets/successtake.png";
import successfullyunstaked from "../assets/successunstake.png";
import successfullyclaimed from "../assets/successclaim.png";
import successfullyapp from "../assets/succapp.png";
import bill from "../assets/bill.png";
import coin from "../assets/coin.png";

const nftRarity = {
    "Legendary": legendary,
    "Epic": epic,
    "Rare": rare,
    "Common": common,
}

const nftImages = {
    1: nft1,
    2: nft2,
    3: nft3,
    4: nft4,
    5: nft5,
    6: nft6,
    7: nft7,
    8: nft8,
    9: nft9,
    10: nft10,
    11: nft11,
    12: nft12,
    13: nft13,
    14: nft14,
    15: nft15,
    16: nft16,
    17: nft17
};
const images = {
    mintButton,
    rolling,
    unreveal,
    connect,
    close,
    chest,
    minus,
    plus,
    openButton,
    rollingart,
    stake,
    unstake,
    stakeb,
    mintb,
    openb,
    presale,
    what,
    what2,
    inbox,
    nftImages,
    approve,
    withdraw,
    common,
    rare,
    epic,
    legendary,
    nftRarity,
    textmint,
    floating,
    textopenpoff,
    textclaimpoff,
    textapppoff,
    textstakepoff,
    textunstakepoff,
    textaftermint,
    textafteropen,
    successfullyunstaked,
    successfullystaked,
    successfullyclaimed,
    successfullyapp,
    coin,
    bill


};

export default images;
