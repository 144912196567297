import {useState} from "react";
import styled from 'styled-components'
import Token from './Token';
import img4 from '../assets/frontpage/float.gif';

const Section = styled.section`
    background-color: #473266;
    display: flex;
    height: 100vh;
`
const HalfContainer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    font-size: 1.5em;
    @media (min-width: 600px) {
        font-size: 2.5em;
    }

    img {
        width: 300px;
        @media (min-width: 600px) {
            font-size: 1.5em;
            width: 600px;
        }
`

const ClickableHalfContainer = styled(HalfContainer)`
    cursor: pointer;
`;

const Container2 = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FrontPage = () => {
    const [start, setStart] = useState(false);

    const enterSite = async () => {
        setStart(true);
    }

    return (
        <Section>
            <Container2>
                <div className={`secti ${start ? "hiddenav" : ""}`}>
                    <ClickableHalfContainer className="pointer" onClick={enterSite}>
                        <img src={img4} alt=""></img>
                    </ClickableHalfContainer>
                    <HalfContainer>
                        <br></br>Do it! Click the box!
                    </HalfContainer>
                </div>
            </Container2>

            <div className={`secti ${!start ? "hiddenav" : ""}`}>
                <Token/>
            </div>
        </Section>
    )
}

export default FrontPage