import React from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import MainSite from "./MainSite";

import {createWeb3Modal, defaultConfig} from "@web3modal/ethers/react";
import FrontPage from "./frontpage/FrontPage";

// 1. Get projectId
const projectId = "YOUR_PROJECT_ID";

// 2. Set chains
const mainnet = {
    chainId: 11155111,
    name: "Sepolia testnet",
    currency: "ETH",
    explorerUrl: "https://basescan.org",
    rpcUrl: "wss://sepolia.drpc.org",
};

// 3. Create a metadata object
const metadata = {
    name: "MemeLoot",
    description: "Whats in the box!?",
    url: "https://memeloot.com", // origin must match your domain & subdomain
    icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    // enableEIP6963: true, // true by default
    //enableInjected: true, // true by default
    // enableCoinbase: true, // true by default
    // rpcUrl: '...', // used for the Coinbase SDK
    //  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<FrontPage/>}/>
                <Route path="/main" element={<MainSite/>}/>
            </Routes>
        </Router>
    )
}

export default App;
