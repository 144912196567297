import React from 'react';
import styled from 'styled-components';
import { LogoText } from '../styling/styling';
import images from '../styling/images';

const ModalOverlay = styled.div`
    position: fixed;
    top: 80px; // Adjust this value to match the height of your top menu
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #1a1a1a;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    max-height: calc(100vh - 120px); // Adjust this value to ensure it doesn't overflow
    overflow-y: auto;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ModalTitle = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1rem;
`;

const ModalText = styled.p`
    margin-bottom: 1rem;
`;

const ApproveButton = styled(LogoText)`
    margin-top: 1rem;
`;

const ApprovalForAllModal = ({ isOpen, onApprove }) => {
    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContent>
                <ModalTitle>Approval Required</ModalTitle>
                <ModalText>
                    To stake your meme NFTs, the staking contract first need your approval to do so.
                    This is a one-time action and is required for security reasons.
                </ModalText>
                <ModalText>
                    Once approved, you'll be able to stake and unstake your NFTs.
                </ModalText>
                <ApproveButton onClick={onApprove}>
                    <img src={images.approve} width="150px" alt="Approve" />
                </ApproveButton>
            </ModalContent>
        </ModalOverlay>
    );
};

export default ApprovalForAllModal;

