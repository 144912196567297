import styled, { css } from "styled-components";

export const Container = styled.div`
  margin: 10px;
  transition: all 0.4s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  

`;

export const Knappis = styled.div`

  transition: all 0.4s ease;
  img{
    width: 140px;  
  }
 
  cursor: Pointer;
  &:hover {
    transform: scale(1.01);
  }
  @media (max-width: 600px) {
    img{
      width: 100px;  
    }
    
  }
`;

export const InkDeck = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Box1 = styled.div`
  width: 300px;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  @media (min-width: 600px) {
    width: 450px;
  }

  background: rgba(255, 255, 255, 0.5);
  border-radius: 35px;
  text-align: center;
  justify-content: center;
`;
export const ImageBox = styled.div`
  img {
    width: 250px;
    @media (min-width: 600px) {
      width: 250px;
    }
  }
`;

export const ImageBox2 = styled.div`
  img {
    width: 250px;
    @media (min-width: 600px) {
      width: 250px;
    }
  }
`;

export const Connected1 = styled.div`
width: 100%;
justify-content: center;
align-items: center;
`;

export const Buttondec = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  cursor: Pointer;
  justify-content: center;
  text-align: center;
  margin: 20px;
  @media (max-width: 600px) {
    margin-right: 15px;
  }
`;

export const SubText2 = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: #000000;
  align-self: center;
  @media (max-width: 600px) {
    font-size: 1.2em;
  }
`;
export const SubText3 = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: #000000;
  align-self: center;
  @media (max-width: 600px) {
    font-size: 1.2em;
  }
`;

export const InfoText = styled.p`
    color: #131212;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    white-space: pre-line;
`;

export const SubText4 = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1em;
  color: #ffffff;
  align-self: center;
  @media (max-width: 600px) {
    font-size: 1.2em;
  }
`;

export const SubTextLight = styled.div`
    font-size: 1.5em;

    @media (max-width: 600px) {

        font-size: 1.2em;

    }

    img {

        width: 50vw;
        @media (min-width: 600px) {

            width: 200px;

        }

`;

export const LogoText = styled.div`
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    img{
      width: 150px;  
    }
    @media (max-width: 600px) {
   
      img{
        width: 120px;  
      }
      
       
    }

    ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
    &:hover {
        transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.05)')};
    }
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around; // Distribute items with space around them
  align-items: center;           // Align items vertically in the center
  margin: 20px 0;                // Add some margin for spacing
`;