"use client"

import React, { useCallback, useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  getLootBoxContract,
} from "../contracts/lootBox";
import { getOgKeyContract } from "../contracts/ogKey";
import {
  Container,
  LogoText,
  InkDeck,
  Box1,
  Buttondec,
  Connected1,
  ImageBox,
  SubText2,
  SubText3,
  SubTextLight,
  ImageBox2,
  InfoText
} from "../styling/styling";
import { useWeb3Modal } from "@web3modal/ethers/react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";

import images from "../styling/images";
import WaitForTransactionModal from "../common/waitForTransaction";

const MintButton = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [mintStatus, setMintStatus] = useState();
  const [nrOfMintedBoxes, setNrOfMintedBoxes] = useState(0);
  const [userBoxCount, setUserBoxCount] = useState(0);
  const [show, setShow] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [cost, setCost] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [totalMintCost, setTotalMintCost] = useState(5);
  const [publicMintEnabled, setPublicMintEnabled] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [ogKeyBalance, setOgKeyBalance] = useState(0);

  const exitMint = async () => {
    handleClose(true);
    setMintStatus(null);
  };
  const handleClose = () => setShow(false);

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

const handleIncrement = () => {
  const remainingSlots = 10 - Number(userBoxCount);
  if (mintAmount >= remainingSlots) return;
  setMintAmount(mintAmount + 1);
};

  const fetchNrOfMintedBoxes = useCallback(async () => {
    if (!isConnected) return;
    const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
    try {
      const mintedBoxes = await lootBoxContract.mintedBoxes();
      setNrOfMintedBoxes(mintedBoxes.toString());
    } catch (error) {
      console.error("Failed to fetch nr of minted boxes:", error);
    }
  }, [isConnected, walletProvider]);

  const fetchUserBoxCount = useCallback(async () => {
    if (!isConnected || !address) return;
    const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
    try {
      const boxCount = await lootBoxContract.balanceOf(address, 1);
      setUserBoxCount(boxCount);
    } catch (error) {
      console.error("Failed to fetch user's box count:", error);
    }
  }, [isConnected, walletProvider, address]);

  const checkWhitelistAndOgKey = useCallback(async () => {
    if (!isConnected || !address) return;
    const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
    const ogKeyContract = await getOgKeyContract(ethers, walletProvider);
    try {
      const [whitelistStatus, keyBalance, publicMintStatus] = await Promise.all([
        lootBoxContract.isWhitelisted(address),
        ogKeyContract.balanceOf(address, 1),
        lootBoxContract.publicMintEnabled()
      ]);
      setIsWhitelisted(whitelistStatus);
      setOgKeyBalance(keyBalance);
      setPublicMintEnabled(publicMintStatus);
    } catch (error) {
      console.error("Failed to check whitelist status or OgKey balance:", error);
    }
  }, [isConnected, address, walletProvider]);

  useEffect(() => {
    const fetchMintCost = async () => {
      if (!isConnected) return;
      const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
      try {
        const cost = await lootBoxContract.getBoxPriceInEth();
        const costConverted = parseFloat(ethers.formatUnits(cost, 18));
        setCost(costConverted);
        setTotalMintCost(mintAmount * costConverted); // This will update with current mintAmount
      } catch (error) {
        console.error("Failed to fetch mint cost:", error);
        setCost(parseFloat(process.env.REACT_APP_MINT_COST_FALLBACK));
      }
    };

    const fetchData = async () => {
      if (!isConnected) return;
      await Promise.all([
        fetchMintCost(),
        fetchNrOfMintedBoxes(),
        fetchUserBoxCount(),
        checkWhitelistAndOgKey()
      ]);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000); // Poll every 30 seconds

    return () => clearInterval(intervalId);
  }, [isConnected, walletProvider, fetchNrOfMintedBoxes, fetchUserBoxCount, checkWhitelistAndOgKey, mintAmount]);


  useEffect(() => {
    if (cost) {
      setTotalMintCost(mintAmount * cost);
    }
  }, [mintAmount, cost]);

  async function handleMint() {
    if (userBoxCount >= 10 || isMintingDisabled) {
      //alert("You have reached the maximum limit of 10 boxes per wallet.");
      return;
    }

    setShow(true);

    if (!isConnected) throw Error("User disconnected");
    const lootBoxContract = await getLootBoxContract(ethers, walletProvider);
    lootBoxContract.on("BoxMintedEvent", (to, amount) => {
      if (to === address) {
        setSuccessMessage("Congratulations!\n" + amount + " box(es) minted.");
      }
    });
    try {
      let response = await lootBoxContract.mintBoxWithEth({
        value: ethers.parseEther(totalMintCost.toString()),
      });
      const receipt = await response.wait();
      if(receipt.status === 1) {
        setMintStatus(1);
        setMintAmount(1);
      }
    } catch (err) {
      console.log("error: ", err);
    }
    await fetchNrOfMintedBoxes();
    await fetchUserBoxCount();
  }

  const isMintingDisabled =
      userBoxCount >= 10 ||
      (!publicMintEnabled && !isWhitelisted && ogKeyBalance <= 0);

  return (
      <Container>
        <Box1>
          <ImageBox>
            <img src={images.presale || "/placeholder.svg"} width="400px" alt=""></img>
          </ImageBox>
          <SubTextLight>Amount: {mintAmount}</SubTextLight>
          <InkDeck>
            <Buttondec onClick={handleDecrement}>
              <img src={images.minus || "/placeholder.svg"} width="40px" alt=""></img>
            </Buttondec>
            <Buttondec onClick={handleIncrement}>
              <img src={images.plus || "/placeholder.svg"} width="40px" alt=""></img>
            </Buttondec>
          </InkDeck>
          <div className={`sectimint ${!isConnected ? "hiddenav" : ""}`}>
            <SubText2>Total: {totalMintCost.toFixed(3)} ETH</SubText2>
          </div>
          <Connected1>
            <div className={`sectimint ${!isConnected ? "" : "hiddenav"}`}>
              <LogoText onClick={() => open()}>
                <img src={images.connect || "/placeholder.svg"} alt=""></img>
              </LogoText>
            </div>
            <div className={`sectimint ${!isConnected ? "hiddenav" : ""}`}>
              <SubText3>Total minted: {nrOfMintedBoxes}/1000</SubText3>
              <LogoText onClick={handleMint} style={{ opacity: isMintingDisabled ? 0.5 : 1, cursor: isMintingDisabled ? 'not-allowed' : 'pointer' }}>
                <img src={images.mintButton || "/placeholder.svg"} alt=""></img>
              </LogoText>
              {isMintingDisabled && (
                  <InfoText>
                    {userBoxCount >= 10
                        ? "You have reached the maximum limit of 10 boxes per wallet.\nOpen the boxes to mint more"
                        : "Address not whitelisted for mint, wait for public phase"}
                  </InfoText>
              )}
            </div>
          </Connected1>
        </Box1>
        <Box1>
          <ImageBox>
            <img src={images.inbox || "/placeholder.svg"} alt=""></img>
          </ImageBox>
          <ImageBox2>
            <img src={images.what || "/placeholder.svg"} alt=""></img>
          </ImageBox2>
          <ImageBox2>
            <img src={images.what2 || "/placeholder.svg"} alt=""></img>
          </ImageBox2>
        </Box1>
        <WaitForTransactionModal
            show={show}
            handleClose={handleClose}
            transactionStatus={mintStatus}
            waitForTransImage={images.floating}
            transFinishedImage={images.floating}
            waitForTransactionMessage={"Smash that transaction confirm button\n to mint your Loot Box(es)!"}
            successMessage={successMessage}
            exitMint={exitMint}
        />
      </Container>
  );
};

export default MintButton;

