import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import img8 from '../assets/frontpage/x.png';
import img9 from '../assets/frontpage/tele.png';
import img10 from '../assets/frontpage/dex.png';
import img18 from '../assets/frontpage/opensea.png';
import img11 from '../assets/frontpage/forthec.png';
import img12 from '../assets/frontpage/memeloot2.png';
import img13 from '../assets/frontpage/basewhite.png';
import img14 from '../assets/frontpage/think.png';
import img15 from '../assets/frontpage/PepeDicaprio.png';
import img2 from '../assets/frontpage/peng_low.png';
import img3 from '../assets/frontpage/shutup.png';
import img4 from '../assets/frontpage/pepemonitor.png';
import img5 from '../assets/frontpage/trepepe.png';
import img6 from '../assets/kista.webp';
import img16 from '../assets/frontpage/mintandstake.png';
import img17 from '../assets/frontpage/tokenomiks.png';
import img19 from '../assets/frontpage/whitepaper.png';

const Section = styled.div`
    width: 100vw;
    color: #FFFFFF;
    flex-wrap: wrap;
    display: flex;
    background-color: #473266;
    justify-content: center;
    align-items: top;
`

const HoverableContainer = styled.div`
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: scale(1.01);
    }
`

const Subcontainer2 = styled.div`
    margin: 10px;
    margin-top: 0px;
    cursor: pointer;
    &:hover {
        transform: scale(1.11);
    }
`

const ContainerTop = styled.div`
    width: 100vw;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    font-size: 2.5em;
    margin-bottom: 0px;

    img {
        width: 270px;
    }

    @media (min-width: 700px) {
        img {
            width: 490px;
        }
    }
`

const Container = styled.div`
    width: 300px;
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (min-width: 700px) {
        margin: 20px;
    }
`

const Inbet = styled.div`
    text-align: center;
    font-size: 1.5em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`

const Subtext1 = styled.div`
    text-align: center;
    font-size: 1.2em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
`

const TextContainer = styled(HoverableContainer)`
    width: 300px;
    overflow-wrap: break-word;
    background-color: #42498d;
    border-radius: 30px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
`

const TextContainer7 = styled(HoverableContainer)`
    width: 300px;
    overflow-wrap: break-word;
    background-color: #42498d;
    display: flex;
    border-radius: 30px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
`

const TextContainer4 = styled(HoverableContainer)`
    width: 300px;
    padding: 20px;
    background-color: #42498d;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
`

const HalfContainer = styled(HoverableContainer)`
    width: 300px;
    height: 220px;
    background-color: #42498d;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
`

const HalfContainer2 = styled(HoverableContainer)`
    width: 300px;
    height: 270px;
    background-color: #FFFFFF;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    background-image: url(${img5});
    background-size: 100%;
`

const HalfContainer5 = styled(HoverableContainer)`
    width: 300px;
    height: 270px;
    background-color: #FFFFFF;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    background-image: url(${img14});
    background-size: 100%;
`

const HalfContainer3 = styled(HoverableContainer)`
    width: 300px;
    height: 200px;
    background-color: #FFFFFF;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    background-image: url(${img3});
    background-size: 100%;
`

const HalfContainer8 = styled(HoverableContainer)`
    width: 300px;
    height: 180px;
    background-color: #FFFFFF;
    border-radius: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1em;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    background-image: url(${img4});
    background-size: 100%;
`

const Round = styled(HoverableContainer)`
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
    padding-top: 45px;
    background-color: #42498d;
    border-radius: 200px;
    text-align: center;
    font-size: 1em;
    color: #000000;
    justify-content: center;
    align-items: center;
    background-image: url(${img15});
    background-size: 120%;
`

const Round2 = styled(HoverableContainer)`
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
    padding-top: 0px;
    background-color: #42498d;
    border-radius: 200px;
    text-align: center;
    font-size: 1em;
    color: #000000;
    justify-content: center;
    align-items: center;

    img {
        width: 300px;
    }
`

const ClickableContainer = styled(HoverableContainer)`
    position: relative;
    overflow: hidden;

    &::after {
        content: 'Tap to enter mint and staking site';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 8px;
        font-size: 14px;
        text-align: center;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
    }

    @media (hover: none) {
        &:active::after {
            transform: translateY(0);
        }
    }

    @media (hover: hover) {
        &:hover::after {
            transform: translateY(0);
        }
    }
`

const ClickableImage = styled(Link)`
  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  @media (hover: none) {
    &:active::before {
      opacity: 1;
    }
  }

  @media (hover: hover) {
    &:hover::before {
      opacity: 1;
    }
  }
`

const Token = () => {
    return (
        <Section>
            <ContainerTop>
                <ClickableImage to="">
                    <img src={img12} alt="" />
                </ClickableImage>
            </ContainerTop>
            <Container>
                
                    <TextContainer4>
                        <ClickableImage to="">
                            <img src={img11} width="190px" alt="" />
                        </ClickableImage>
                    </TextContainer4>
        
             
                    <HalfContainer2>
                        <Link to="" style={{ display: 'block', width: '100%', height: '100%' }}></Link>
                    </HalfContainer2>
              
                <Inbet>Cooking some $Loot on Base.</Inbet>
                <TextContainer7>
                    <Subcontainer2>
                        <a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer">
                            <img src={img8} width="40px" alt="" />
                        </a>
                    </Subcontainer2>
                    <Subcontainer2>
                        <a href="https://t.me/MemeLootcom" target="_blank" rel="noreferrer">
                            <img src={img9} width="50px" alt="" />
                        </a>
                    </Subcontainer2>
                    <Subcontainer2>
                        <a href="" target="http://dexscreener.com" rel="noreferrer">
                            <img src={img10} width="45px" alt="" />
                        </a>
                    </Subcontainer2>
                    <Subcontainer2>
                        <a href="https://opensea.io/" target="_blank" rel="noreferrer">
                            <img src={img18} width="45px" alt="" />
                        </a>
                    </Subcontainer2>
                </TextContainer7>
                <ClickableContainer>
                    <TextContainer>
                        <ClickableImage to="/main">
                            <img src={img16} width="190px" alt="" />
                        </ClickableImage>
                    </TextContainer>
                </ClickableContainer>
              
                    <HalfContainer>
                        <ClickableImage to="/main">
                            <img src={img6} width="270px" alt="" />
                        </ClickableImage>
                    </HalfContainer>
      
                <Inbet>Meme art and coins in a Loot box</Inbet>
            </Container>
           
                <Container>
                    <HalfContainer3>
                        <Link to="" style={{ display: 'block', width: '100%', height: '100%' }}></Link>
                    </HalfContainer3>
                    <Inbet>Shut up and take my money!</Inbet>
                    <TextContainer>
                        <ClickableImage to="">
                            <img src={img13} width="190px" alt="" />
                        </ClickableImage>
                    </TextContainer>
                    <Inbet>We are on Base</Inbet>
                    <HalfContainer5>
                        <Link to="" style={{ display: 'block', width: '100%', height: '100%' }}></Link>
                    </HalfContainer5>
                    <TextContainer>
                        <a
                            href={`https://basescan.org/TBA`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}
                        >
                            CA: TBD
                        </a>
                    </TextContainer>
                    <HalfContainer8>
                        <Link to="" style={{ display: 'block', width: '100%', height: '100%' }}></Link>
                    </HalfContainer8>
                    <Inbet>The frog never sleep </Inbet>
                </Container>
           
         
                <Container>
                    <Round>
                        <Link to="" style={{ display: 'block', width: '100%', height: '100%' }}></Link>
                    </Round>
                    <TextContainer>
                        <ClickableImage to="">
                            <img src={img17} width="190px" alt="" />
                        </ClickableImage>
                        <Subtext1>
                            Chain: Base <br />
                            Supply: 1 BILLION<br />
                            Ticker: $LOOT<br />
                            LP Fee: 1%<br />
                        </Subtext1>
                    </TextContainer>
                
                    <TextContainer>
                        <a
                            href={`https://whitepaper.memeloot.com`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}
                        >
                            <img src={img19} width="190px" alt="" /></a>
                         </TextContainer>
                
                    <Round2>
                        <ClickableImage to="">
                            <img src={img2} alt="" />
                        </ClickableImage>
                    </Round2>
                    <TextContainer>
                        <ClickableImage to="">
                            <img src={img12} width="250px" alt="" />
                        </ClickableImage>
                        <Subtext1>is a memecoin powered by Evil Twins Collective. MemeLoot is a meme coin without utility. At the most you will get some more coins or Art. Buy at own risk</Subtext1>
                    </TextContainer>
                </Container>
            
        </Section>
    )
}

export default Token

