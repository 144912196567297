import { useState } from "react";
import styled from "styled-components";
import images from "./styling/images";
import MintSite from "./mintPage/MintSite";
import OpenChest from "./openChest/OpenChest";
import Stake from "./staking/Stake";

//import LogoText from
const Section = styled.section`
  width: 100vw;

  background-color: #473266;
`;

const NavButton = styled.img`
  width: 120px;
    
    &:hover {
        transform: translateY(-2px);
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }

    @media (min-width: 600px) {
        width: 160px;
    }
    
    @media (min-width: 1000px) {
        width: 200px;
    }
`;

const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
`;

const SolidContainer = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    width: 550px;
  }
`;

const SubContainer = styled.div`
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
`;

const MainSite = () => {
  const [mint, setMint] = useState(false);
  const [stake, setStake] = useState(true);
  const [open, setOpen] = useState(true);

  const enterMint = async () => {
    setMint(false);
    setStake(true);
    setOpen(true);
  };

  const enterStake = async () => {
    setMint(true);
    setStake(false);
    setOpen(true);
  };

  const enterOpen = async () => {
    setMint(true);
    setStake(true);
    setOpen(false);
  };

  return (
    <Section>
      <Container>
        <SolidContainer>
          <SubContainer onClick={enterMint}><NavButton src={images.mintb} alt=""></NavButton></SubContainer>
          <SubContainer onClick={enterOpen}><NavButton src={images.openb} alt=""></NavButton></SubContainer>
          <SubContainer onClick={enterStake}><NavButton src={images.stakeb} alt=""></NavButton></SubContainer>
        </SolidContainer>
      </Container>

      <div className={`secti ${mint ? "hiddenav" : ""}`}>
        <MintSite></MintSite>
      </div>
      <div className={`secti ${open ? "hiddenav" : ""}`}>
        <OpenChest></OpenChest>
      </div>
      <div className={`secti ${stake ? "hiddenav" : ""}`}>
        <Stake></Stake>
      </div>
    </Section>
  );
};

export default MainSite;
